<mat-drawer-container class="container" autosize>
    <mat-drawer #drawer (openedChange)="onOpenedChange($event)" class="sidenav" mode="push" opened>
        <p class="drawer-title">{{strings["warningsAndErrors"]}}</p>
        <p class="drawer-subtitle">{{integrationName}}</p>
        <mat-selection-list [multiple]="false">
            <mat-list-option  (click)="setData(date.utcDate)" (click)="setDataStudioTitle(date.longDate)" (click)="drawer.toggle()" class="date-item" *ngFor="let date of dates" [value]="date.longDate">
                {{date.longDate}}
                <mat-divider></mat-divider>
            </mat-list-option>
        </mat-selection-list>
    </mat-drawer>

    <div class="sidenav-content">
        <button mat-button class="sidenav-toggle-open" (click)="drawer.toggle()">
            <img  src="../../assets/images/icons/FillchevronRight.svg" [ngClass]="{ 'chevron-left': isDrawerOpen==false, 'chevron-right': isDrawerOpen==true }">
        </button>
        <div class="openView">
        <div *ngIf="hasData" class="log-text">
            <div *ngIf="showNoLogs">{{strings["noErrors"]}} </div>
            <p *ngFor="let e of errors">
                {{e}}
            </p>
        </div>
        <div class="exports" *ngIf="saveIconShow">
            <div>JSON: </div>
            <img class="json-download-icon" src="../../assets/images/icons/FilldownloadFile.svg" 
                (click)="downloadJson()">
        </div>
    </div>
    </div>
</mat-drawer-container>
