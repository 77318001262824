import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDrawer } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { DataLakeFileDateListing } from 'src/app/models/data-lake/files/data-lake-file-date-listing';
import { DataLakeFileListing } from 'src/app/models/data-lake/files/data-lake-file-listing';
import { DataLakeService } from 'src/app/services/data-lake.service';
import { DisplayService } from 'src/app/services/display.service';
import { StringLocalizerService } from "src/app/services/string-localizer.service";


@Component({
  selector: 'app-data-lake',
  templateUrl: './data-lake.component.html',
  styleUrls: ['./data-lake.component.css']
})
export class DataLakeComponent implements OnInit {
  integrationName: string;
  private solutionNameSubscription: Subscription = null;
  selectedDataLakeSolution: string = "";
  selectedDataLakeData: any = null;
  selected3 = [];
  show = false;
  isMasterSel: boolean = false;
  categoryList: any;
  checkedCategoryList: any;
  fileDates: DataLakeFileDateListing[];
  files: DataLakeFileListing[] = [];
  selectedDate: string = null;
  noFiles: boolean = false;
  public isDrawerOpen: boolean = false;
  toggleSearchCoontainer: boolean = false;
  searchFileList: string = "";
  filteredFiles: DataLakeFileListing[] = [];
  public strings: {} = {};
  @ViewChild('drawer', { static: false }) public drawer?: MatDrawer;
  
  constructor(
    private dataLakeService: DataLakeService,
    private displayService: DisplayService,
    private stringLocalizerService: StringLocalizerService) {
      this.stringLocalizerService.strings.subscribe(s => {
        if (s) {
          this.strings = this.stringLocalizerService.getSection("output");
        }
      })
  }

  ngOnInit() {
    this.dataLakeService.$dataLakeFiles.subscribe(dataLakeFiles => {
      if (dataLakeFiles != null) {
        this.selectedDataLakeSolution = this.displayService.$selectedDataLakeSolution.value;
        this.integrationName =  this.selectedDataLakeSolution;
        var selectedDataLakeData = dataLakeFiles.solutionListings.find(s => s.name == this.selectedDataLakeSolution);
        if (selectedDataLakeData != null) {
          this.fileDates = selectedDataLakeData.dateListings;
          this.drawer?.open()
        }
      }
      else {
        this.fileDates = [];
        this.drawer?.open();
      }
    });
  }

  //Get names of all files under a certain integration + date
  async getFileNames(date: string): Promise<void> {
    this.displayService.$selectedGrayOut.next(true);
    this.show = true;
    if (this.selectedDate == null || this.selectedDate != date) {
      this.selectedDate = date;
      var fileListing = [];
      await this.dataLakeService.loadDataLakeFiles(this.selectedDataLakeSolution, date);
      this.dataLakeService.$dataLakeFiles.subscribe(dataLakeFiles => {
        dataLakeFiles.solutionListings.find(s => s.name == this.selectedDataLakeSolution).dateListings.find(d => d.name == date).filesListing.forEach(f => {
          fileListing = fileListing.concat(f);
          this.displayService.$selectedGrayOut.next(false);
          this.drawer?.close();
        });
      });
      this.files = fileListing;
      this.filteredFiles = [...this.files];
      this.toggleSearchCoontainer = true;
      this.displayService.$selectedGrayOut.next(false);
      this.drawer?.close();
      if (this.files.length == 0) {
        this.noFiles = true;
        this.displayService.$selectedGrayOut.next(false);
        this.drawer?.close();
      } else {
        this.noFiles = false;
        this.displayService.$selectedGrayOut.next(false);
        this.drawer?.close();
      }
    }
    this.displayService.$selectedGrayOut.next(false);
  }


  //Download selected file to browser
  async downloadFile(file: DataLakeFileListing): Promise<void> {
    await this.dataLakeService
      .getIOFile(this.selectedDataLakeSolution, this.selectedDate, `${file.name}.${file.extension}`).then(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = `${file.name}.${file.extension}`;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  setDataStudioTitle(title: string) {
    this.displayService.setDataStudioTitle(title);
  }

  //Track if mat-drawer is open or close.
  onOpenedChange(event) {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  toggle(filteredFiles, event: MatCheckboxChange) {
    if (event.checked) {
      this.selected3.push(filteredFiles);
    } else {
      const index = this.selected3.indexOf(filteredFiles);
      if (index >= 0) {
        this.selected3.splice(index, 1);
      }
    }
  }

  exists(item) {
    return this.selected3.indexOf(item) > -1;
  };

  isIndeterminate() {
    return (this.selected3.length > 0 && !this.isChecked());
  };

  isChecked() {
    return this.selected3.length === this.filteredFiles.length;
  };



  toggleAll(event: MatCheckboxChange) {

    if (event.checked) {

      this.filteredFiles.forEach(row => {
        this.selected3.push(row)
      });

    } else {
      this.selected3.length = 0;
    }
  }
  downloadAllChecked() {
    this.selected3.forEach(filteredFiles => {
      this.downloadFile(filteredFiles)
    })
  }

  // Filter Files
  filterFileList() {
    const searchLower = this.searchFileList.toLocaleLowerCase();
    this.filteredFiles = this.files.filter((file) =>
      file.name.toLowerCase().includes(searchLower)
    );
  }
}